import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { isMobile } from "react-device-detect";
import Modal from "react-modal";

import ciazearn from "../../assets/caiz-earn-page/CaizEarnIpad.jpg";
import badge5 from "../../assets/caiz-earn-page/5percent.jpg";
import badge7 from "../../assets/caiz-earn-page/7percent.jpg";

import developers from "../../assets/caiz-earn-page/developers.svg";
import lock from "../../assets/caiz-earn-page/lock.svg";
import numberedBlocks from "../../assets/caiz-earn-page/numbered-blocks.svg";
import coins from "../../assets/caiz-earn-page/coins.svg";

import Greenpaper from "../../assets/caiz-earn-page/greenpaper";
import Whitepaper from "../../assets/caiz-earn-page/whitepaper";
import Downloads from "../../assets/caiz-earn-page/downloads";
import Lightpaper from "../../assets/caiz-earn-page/lightpaper";

import linkIcon from "../../assets/caiz-earn-page/link-icon.svg";
import videoPlayIcon from "../../assets/caiz-earn-page/video-play-icon.svg";
import MetaMask from "../../assets/icons/metamask.svg";

import LoginLoader from "../../components/login-loader/login-loader";
import ErrorModal from "../../components/dashboard/error-modal";

import "./index.scss";

const Home = () => {
  const cookies = new Cookies();

  let [popUpVideo, setPopUpVideo] = useState(false);
  const [isMetamaskInstalled, setIsMetamaskInstalled] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [errorDescription, setErrorDescription] = useState("");
  const [loaderData, setLoaderData] = useState({
    logo: MetaMask,
    title: "Metamask",
    TileBackgroundColor: "#f69f1c",
  });
  let useNetworkID = "1"; // "11155111" for sepolia, "1" for mainnet, "5" for goerli
  const [network, setNetwork] = useState(useNetworkID);

  const navigate = useNavigate();

  useEffect(() => {
    if (window.ethereum) {
      setIsMetamaskInstalled(true);
      window.ethereum
        .request({ method: "net_version" })
        .then(function (result) {
          setNetwork(result);
        });
    }
  }, []);

  window.addEventListener("load", function () {
    if (window.ethereum) {
      setIsMetamaskInstalled(true);
      console.log(window.ethereum);

      // // use MetaMask's provider
      // window.ethereum.enable(); // get permission to access accounts

      // detect Metamask account change

      window.ethereum.on("accountsChanged", function (accounts) {
        console.log("accountsChanges", accounts[0]);
        if (accounts.length !== 0) {
          setErrorModalIsOpen(true);
          setErrorDescription(
            "account has Changed please reconnect with this account"
          );
        }
      });

      // detect Network account change
      window.ethereum.on("networkChanged", function (networkId) {
        console.log("accountsChanges", networkId);
        setNetwork(networkId);
        if (networkId !== useNetworkID) {
          setErrorModalIsOpen(true);
          setErrorDescription(
            "Network has changed, please return to the main network"
          );
        } else {
          setErrorModalIsOpen(false);
        }
      });
    } else if (!isMobile) {
      setErrorModalIsOpen(true);
      setErrorDescription("Please install metamask");
      // window.open("https://metamask.io/download/", "_blank");
    }
  });
  const [account, setAccount] = useState("");

  let mobileMessage = null;
  if (isMobile) {
    mobileMessage = (
      <div className="desktop-only">
        Please open this Caiz Earn page on your computer
      </div>
    );
  }
  const handleRedirect = async () => {
    console.log("hr");
    if (isMetamaskInstalled) {
      try {
        if (network === useNetworkID) {
          setErrorModalIsOpen(false);
          setIsOpen(true);
          setErrorModalIsOpen(false);
          await window.ethereum
            .request({
              method: "eth_requestAccounts",
            })
            .then((accounts) => {
              cookies.set("WID", accounts[0], {
                path: "/",
                secure: true,
              });
              navigate("/dashboard");
              setIsOpen(false);
            })
            .catch((error) => {
              console.log("metamask1 ", error);
              setIsOpen(false);
              setErrorModalIsOpen(true);
              setErrorDescription(error.message);
            });
        } else if (!isMobile) {
          setIsOpen(false);
          setErrorModalIsOpen(true);
          setErrorDescription(
            "You are connected to a wrong network. Please change to Ethereum Mainnet in your wallet.",
            network
          );
        }
      } catch (error) {
        console.log("metamask2 ", error);
        setIsOpen(false);
        setErrorModalIsOpen(true);
        setErrorDescription(error.message);
      }
    } else {
      setIsOpen(false);
      setErrorModalIsOpen(true);
      setErrorDescription("Please install metamask");
    }
  };

  const handleClick = async (numberOfMonths) => {
    if (isMobile) {
      // const provider = new WalletConnectConnector({
      //   rpc: {
      //     1: "https://mainnet.infura.io/v3/24ee455a6a0e4c058ba2bbc6323e1fe7", // Replace with your Infura Project ID
      //     5: "https://goerli.infura.io/v3/24ee455a6a0e4c058ba2bbc6323e1fe7", // Replace with your Infura Project ID
      //   },
      //   bridge: "https://bridge.walletconnect.org",
      // });
      // const web3 = new Web3(provider);
      // try {
      //   await provider.deactivate();
      //   setAccount("");
      //   //Enable the provider to connect to the user's wallet
      //   await provider.activate();
      //   // Get the user's account
      //   const accounts = await web3.eth.getAccounts();
      //   // Update the state with the user's account
      //   setAccount(accounts[0]);
      //   navigate("/form", {
      //     state: { numberOfMonths: numberOfMonths },
      //   });
      // } catch (error) {
      //   console.error(error);
      // }
    } else {
      if (isMetamaskInstalled) {
        try {
          if (network === useNetworkID) {
            setErrorModalIsOpen(false);
            setIsOpen(true);
            setErrorModalIsOpen(false);
            await window.ethereum
              .request({
                method: "eth_requestAccounts",
              })
              .then((accounts) => {
                cookies.set("WID", accounts[0], {
                  path: "/",
                  secure: true,
                });
                navigate("/form", {
                  state: { numberOfMonths: numberOfMonths },
                });
                setIsOpen(false);
              })
              .catch((error) => {
                console.log("metamask1 ", error);
                setIsOpen(false);
                setErrorModalIsOpen(true);
                setErrorDescription(error.message);
              });
          } else if (!isMobile) {
            setIsOpen(false);
            setErrorModalIsOpen(true);
            setErrorDescription(
              "You are connected to a wrong network. Please change to Ethereum Mainnet in your wallet."
            );
          }
        } catch (error) {
          console.log("metamask2 ", error);
          setIsOpen(false);
          setErrorModalIsOpen(true);
          setErrorDescription(error.message);
        }
      } else {
        setIsOpen(false);
        setErrorModalIsOpen(true);
        setErrorDescription("Please install metamask");
        // window.open("https://metamask.io/download/", "_blank");
      }
    }
  };

  function closeModal() {
    setIsOpen(false);
  }

  function closeErrorModal() {
    setErrorModalIsOpen(false);
  }

  const errorCustomStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0",
      borderRadius: "4px",
      width: "75%",
      maxWidth: "400px",
    },
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0",
      borderRadius: "4px",
      width: "75%",
      maxWidth: "400px",
    },
  };

  return (
    <>
      {/* section1 */}
      <section className="section1 ">
        <div className="hero-bos">
          <div className="hero container">
            <div className="hero-contain">
              <h1 className="">Caiz Earn Program</h1>
              <p className="vizeble-text">
                Caiz Earn is a liquidity provision program within the Caiz
                Ecosystem, allowing users to lock their Caizcoin into a
                liquidity pool wallet and provide liquidity services via
                predefined market-making algorithms. This activity helps
                stabilize and promote ecosystem growth while enabling users to
                earn.The program operates in coordination with the Exchange
                Liquidity Wallet and the other locked Caizcoin in the Caiz Earn
                program.
              </p>
            </div>
            <img src={ciazearn} className="hero-image" alt="ciaz-earn" />
            <p className="hidden-text vizeble-text">
              Caiz Earn is a liquidity provision program within the Caiz
              Ecosystem, allowing users to lock their Caizcoin into a liquidity
              pool wallet and provide liquidity services via predefined
              market-making algorithms. This activity helps stabilize and
              promote ecosystem growth while enabling users to earn.The program
              operates in coordination with the Exchange Liquidity Wallet and
              the other locked Caizcoin in the Caiz Earn program.
            </p>
          </div>
          {/* divider */}
          <div className="clip-pathpoint">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              viewBox="0 0 1200 134"
              preserveAspectRatio="none"
            >
              <path
                fill="#f8f9fa"
                d="M509.79678,40 C516.59356,40 523.991852,44.618478 528.357335,48.9839608 L586.016039,106.642665 C590.744031,111.370657 592.950798,112.592859 595.603684,113.39694 C598.25657,114.20102 600.969618,114.20102 603.622504,113.39694 L604.148012,113.229634 C606.580831,112.414318 608.797356,111.055458 613.210148,106.642665 L670.868853,48.9839608 C675.234336,44.618478 682.858816,40 689.429408,40 L1185,40 C1193.28427,40 1200,46.7157288 1200,55 L1200,135 L0,135 L0,55 C0,46.7157288 6.71572875,40 15,40 L509.79678,40 Z"
              ></path>
              <defs></defs>
            </svg>
          </div>
        </div>
      </section>

      {/* section2 */}
      <section className="section2" id="percentageImages">
        <div>
          <div className="sec2-text ">
            <h2 className="">I want to participate in the Caiz Earn Program</h2>
            <p className="">Click to pool coins for 5% or 7%.</p>
          </div>

          <div className="badgemain">
            {/* box1 */}
            <div className="budge-box" onClick={() => handleClick(6)}>
              <img src={badge5} className="badimage" alt="badge5" />

              <div className="budgetext">
                <div>6 months</div>

                <h3 className="">5% return per year</h3>
              </div>
            </div>

            {/* box2 */}
            <div className="budge-box" onClick={() => handleClick(12)}>
              <img src={badge7} className="badimage" alt="badge7" />

              <div className="budgetext">
                <div>12 months</div>

                <h3 className="">7% return per year</h3>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Wallet Modal"
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
        >
          <LoginLoader
            loaderLogo={loaderData.logo}
            loaderTitle={loaderData.title}
            TileBackgroundColor={loaderData.TileBackgroundColor}
            description={errorDescription}
            errorModalIsOpen={errorModalIsOpen}
          />
        </Modal>
        <Modal
          isOpen={errorModalIsOpen}
          onRequestClose={closeErrorModal}
          style={errorCustomStyles}
          contentLabel="Wallet Modal"
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
        >
          <ErrorModal
            description={errorDescription}
            buttonFunction={closeErrorModal}
          />
        </Modal>
      </section>

      {/* section3 */}
      <section className="section3">
        {/* divider */}
        <svg
          className="topSvg"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          viewBox="0 0 1200 116"
          preserveAspectRatio="none"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          transform="matrix(-1,1.2246467991473532e-16,-1.2246467991473532e-16,-1,0,0)"
        >
          <path
            fill="#F8F9FA"
            d="M0,110 C200,76.6666667 400,60 600,60 C800,60 1000,76.6666667 1200,110 L1200,116 L0,116 L0,110 Z"
          ></path>
          <defs></defs>
        </svg>
        <div>
          <div className="tokens-sec">
            <h1 className="">Already locked your tokens?</h1>
            <div className="" onClick={handleRedirect}>
              <img
                src={linkIcon}
                className="coin-icon"
                width={25}
                alt="coin-icon"
              />
              Connect wallet
            </div>
          </div>
        </div>
        {/* divider */}
        <svg
          className="bottomSvg"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          viewBox="0 0 1200 116"
          preserveAspectRatio="none"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          transform="matrix(-1,1.2246467991473532e-16,-1.2246467991473532e-16,-1,0,0)"
        >
          <path
            fill="#ffffff"
            d="M0,110 C200,76.6666667 400,60 600,60 C800,60 1000,76.6666667 1200,110 L1200,116 L0,116 L0,110 Z"
          ></path>
          <defs></defs>
        </svg>
      </section>

      {/* section4 */}
      <section className="section4">
        <div>
          <div className="sec4-text">
            <h2 className="">Which benefits Caiz Earn have?</h2>
          </div>

          <div className="benefit-flexbox container">
            {/* benefitboxs1 */}
            <div className="benefitbox">
              <div>
                {/* icon-div */}
                <div className="benefit-icon">
                  <img
                    src={developers}
                    className="benefit-icons"
                    alt="developers"
                  />
                  <h6>Benefits #1</h6>
                </div>

                {/* heading-text */}
                <div className="benefit-text">
                  <h2 className="">Active role in development</h2>
                  <p className="">
                    By providing liquidity to the Caiz ecosystem through the
                    program, users play an active role in the development of the
                    ecosystem. This helps to improve the stability and
                    accessibility of the ecosystem for all users.
                  </p>
                </div>
              </div>
            </div>

            {/* benefitboxs2 */}
            <div className="benefitbox benefitboxcolor">
              <div>
                {/* icon-div */}
                <div className="benefit-icon">
                  <img src={lock} className="benefit-icons" alt="lock" />
                  <h6>Benefits #2</h6>
                </div>

                {/* heading-text */}
                <div className="benefit-text">
                  <h2 className="">Security</h2>
                  <p className="">
                    By providing liquidity to the Caiz ecosystem through the
                    program, users play an active role in the development of the
                    ecosystem. This helps to improve the stability and
                    accessibility of the ecosystem for all users.
                  </p>
                </div>
              </div>
            </div>

            {/* benefitboxs3 */}
            <div className="benefitbox benefitboxcolor">
              <div>
                {/* icon-div */}
                <div className="benefit-icon">
                  <img
                    src={numberedBlocks}
                    className="benefit-icons"
                    alt="numbered-blocks"
                  />

                  <h6>Benefits #3</h6>
                </div>

                {/* heading-text */}
                <div className="benefit-text">
                  <h2 className="">Priority usage</h2>
                  <p className="">
                    By providing liquidity to the Caiz ecosystem through the
                    program, users play an active role in the development of the
                    ecosystem. This helps to improve the stability and
                    accessibility of the ecosystem for all users.
                  </p>
                </div>
              </div>
            </div>

            {/* benefitboxs4 */}
            <div className="benefitbox ">
              <div>
                {/* icon-div */}
                <div className="benefit-icon">
                  <img src={coins} className="benefit-icons" alt="coins" />
                  <h6>Benefits #4</h6>
                </div>

                {/* heading-text */}
                <div className="benefit-text">
                  <h2 className="">Liquidity</h2>
                  <p className="">
                    By providing liquidity to the Caiz ecosystem through the
                    program, users play an active role in the development of the
                    ecosystem. This helps to improve the stability and
                    accessibility of the ecosystem for all users.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section5 */}
      <section className="section5">
        {/* divider */}
        <svg
          className="topSvg"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          viewBox="0 0 1200 116"
          preserveAspectRatio="none"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          transform="matrix(-1,1.2246467991473532e-16,-1.2246467991473532e-16,-1,0,0)"
        >
          <path
            fill="#ffffff"
            d="M0,110 C200,76.6666667 400,60 600,60 C800,60 1000,76.6666667 1200,110 L1200,116 L0,116 L0,110 Z"
          ></path>
          <defs></defs>
        </svg>
        <div className="popUpVideoContainer">
          <div className="playButton" onClick={() => setPopUpVideo(true)}>
            <img src={videoPlayIcon} width={40} alt="video-play-icon" />
          </div>
        </div>
        {/* pop up vidro */}
        <div
          className={popUpVideo ? "sec5PopUpVideo" : "popVideoClose"}
          onClick={() => setPopUpVideo(false)}
        >
          <div>
            {/* divider */}
            <div className="crossIconContainer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="crossIcon"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </div>
            <iframe
              className="popVideo"
              height="315"
              src={
                popUpVideo
                  ? "https://www.youtube.com/embed/OCYifZ0QrFo?si=bncKaJCXHvUk3sHu"
                  : ""
              }
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>

        {/* divider */}
        <svg
          className="bottomSvg "
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          viewBox="0 0 1200 130"
          preserveAspectRatio="none"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          transform="matrix(-1,1.2246467991473532e-16,-1.2246467991473532e-16,-1,0,0)"
        >
          <path
            fill="#F8F9FA"
            d="M0 130V60c200 33.333 400 50 600 50s400-16.667 600-50v70H0Z"
          ></path>
          <defs></defs>
        </svg>
      </section>

      {/* section6 */}
      <section className="section6">
        <div className="sec6Item1">
          <div className="insutruction">Step-by-Step</div>
          <h4> How to participate in Caiz Earn?</h4>
          <p>
            Follow the step-by-step instructions on the right and lock your
            coins
          </p>
        </div>
        <div className="sec6Item2">
          {/* box 1*/}
          <div className="mergeItems">
            <div className="Nuuber">#1</div>
            <div className="desc">
              <p className="header">
                Choose the locking period between 5% and 7%.
              </p>
              <p className="box1p">
                Lock for 6 months and receive 5% return per year or lock for 12
                months and receive 7% return per year.
              </p>
            </div>
          </div>
          {/* box 2*/}
          <div className="mergeItems">
            <div className="Nuuber">#2</div>
            <div className="desc">
              <p className="header">
                Connect your crypto wallet by following instructions in the
                wallet.
              </p>
            </div>
          </div>
          {/* box 3*/}
          <div className="mergeItems">
            <div className="Nuuber">#3</div>
            <div className="desc">
              <p className="header">
                Enter the number of CAIZcoin token you want to lock and check
                all details.
              </p>
            </div>
          </div>
          {/* box 4*/}
          <div className="mergeItems">
            <div className="Nuuber">#4</div>
            <div className="desc">
              <p className="header">
                Click on lock token and confirm the transaction in your crypto
                wallet.
              </p>
            </div>
          </div>
          {/* box 5*/}
          <div className="mergeItems">
            <div className="Nuuber">#5</div>
            <div className="desc">
              <p className="header">Congrats, you did it right!</p>
            </div>
          </div>
        </div>
      </section>

      {/* section7 */}
      <section className="section7">
        {/* divider */}
        <svg
          className="topSvg "
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          viewBox="0 0 1200 116"
          preserveAspectRatio="none"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          transform="matrix(-1,1.2246467991473532e-16,-1.2246467991473532e-16,-1,0,0)"
        >
          <path
            fill="#F8F9FA"
            d="M0,110 C200,76.6666667 400,60 600,60 C800,60 1000,76.6666667 1200,110 L1200,116 L0,116 L0,110 Z"
          ></path>
          <defs></defs>
        </svg>
        <div>
          <h4>Participate</h4>
          <p>
            You can see the pooled Caizcoin token balance and transaction
            records in the Caiz Earn Dashboard. The rewards will be distributed
            automatically every 3 months to your crypto wallet ERC20 address.
            After expiration of the pooled period, you can release your Caizcoin
            tokens and transfer back to your ERC20 crypto wallet or extend the
            pooling period for earning more rewards. Pool more Caizcoin token
            anytime as you desire. There is no limit for the amount of Caizcoin
            tokens you can pool.
          </p>
          <a href="#percentageImages" class="button-link">Caiz Earn Program</a>
        </div>
        {/* divider */}
        <svg
          className="bottomSvg "
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          viewBox="0 0 1200 116"
          preserveAspectRatio="none"
          version="1.1"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          transform="matrix(-1,1.2246467991473532e-16,-1.2246467991473532e-16,-1,0,0)"
        >
          <path
            fill="#F8F9FA"
            d="M0,110 C200,76.6666667 400,60 600,60 C800,60 1000,76.6666667 1200,110 L1200,116 L0,116 L0,110 Z"
          ></path>
          <defs></defs>
        </svg>
      </section>

      {/* section8 */}
      <section className="section8">
        <div>
          <h4>Explore our resources: Delve deeper into our vision</h4>
          <div className="sec8item2">
            <div>
              <Whitepaper />
              <a href="https://caiz.com/wp-content/uploads/2024/01/Whitepaper_CAIZ.pdf">
                White paper
              </a>
            </div>
            <div>
              <Lightpaper />
              <a href="https://caiz.com/wp-content/uploads/2024/01/Whitepaper_CAIZ.pdf">
                Light paper
              </a>
            </div>
            <div>
              <Greenpaper />
              <a href="https://caiz.com/wp-content/uploads/2024/01/Whitepaper_CAIZ.pdf">
                Green paper
              </a>
            </div>
            <div>
              <Downloads />
              <a href="https://caiz.com/wp-content/uploads/2024/01/Whitepaper_CAIZ.pdf">
                Downloads
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
