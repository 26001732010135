import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/icons/Logo_caizcoin_horizontal_white_menu.svg";
//social media icon
import whatsapp from "../../assets/images/whatsapp-White.svg";
// import discord from "../../assets/images/discord-White.svg";
import facebook from "../../assets/images/facebook-White.svg";
import instagram from "../../assets/images/instagram-White.svg";
import twitter from "../../assets/images/twitter-White.svg";
import linkedln from "../../assets/images/linkedin-White.svg";
import medium from "../../assets/images/medium-White.svg";
import reddit from "../../assets/images/reddit-White.svg";
import telegram from "../../assets/images/telegram-White.svg";
import tiktok from "../../assets/images/tik-tok-White.svg";
import youtube from "../../assets/images/youtube-White.svg";
import iconBackward from "../../assets/icons/Icon-backward.svg";
import iconForward from "../../assets/icons/Icon-forward.svg";
import group from "../../assets/icons/group.svg";
import world from "../../assets/icons/world.png";
import brain from "../../assets/icons/brain.svg";
import handshake from "../../assets/icons/handshake.svg";
import backButton from "../../assets/icons/back-button.svg";
import Logo_caiz_new from '../../assets/icons/Logo_caiz_new.svg';
//main menu icon
import ambassadors from '../../assets/icons/ambassadors.svg';
import caizrelief from '../../assets/icons/caizrelief2.svg';
import circulatingsupply from '../../assets/icons/circulating-supply.svg';
import contacts from '../../assets/icons/contacts.svg';
import coins from '../../assets/icons/coins.svg';
import coinshand from '../../assets/icons/coins-hand.svg';
import community from '../../assets/icons/community.svg';
import desktop from '../../assets/icons/desktop.svg';
import developers from '../../assets/icons/developers.svg';
import downloads from '../../assets/icons/downloads.svg';
import events from '../../assets/icons/events.svg';
import influencers from '../../assets/icons/influencers.svg';
import gift from '../../assets/icons/gift.svg';
import like from '../../assets/icons/like.svg';
import marketing from '../../assets/icons/marketing.svg';
import partnership from '../../assets/icons/partnership.svg';
import piechart from '../../assets/icons/piechart.svg';
import press from '../../assets/icons/press.svg';
import roadmap from '../../assets/icons/roadmap2.svg';
import sharia from '../../assets/icons/sharia.svg';
import star from '../../assets/icons/star.svg';
import suitcase from '../../assets/icons/suitcase.svg';
import team from '../../assets/icons/team2.svg';
import wordings from '../../assets/icons/wordings.svg';
//mobile menu icon
import globe from '../../assets/icons/globe-solid.svg';
import comments from '../../assets/icons/comments-solid.svg';
import handshakeAngle from '../../assets/icons/handshake-angle-solid.svg';
import list from '../../assets/icons/list-solid.svg';
import users from '../../assets/icons/users-solid.svg';
import building from '../../assets/icons/building-solid.svg';
import bullhorn from '../../assets/icons/bullhorn-solid.svg';
import calendarDays from '../../assets/icons/calendar-days-solid.svg';
import chartPie from '../../assets/icons/chart-pie-solid.svg';
import chartSimple from '../../assets/icons/chart-simple-solid.svg';
import coinsMobileMenu from '../../assets/icons/coins-solid.svg';
import download from '../../assets/icons/download-solid.svg';
import fileCircleCheck from '../../assets/icons/file-circle-check-solid.svg';
import handHoldingDollar from '../../assets/icons/hand-holding-dollar-solid.svg';
import hashtag from '../../assets/icons/hashtag-solid.svg';
import heart from '../../assets/icons/heart-solid.svg';
import laptopCode from '../../assets/icons/laptop-code-solid.svg';
import map from '../../assets/icons/map-solid.svg';
import newspaper from '../../assets/icons/newspaper-solid.svg';
import peopleGroup from '../../assets/icons/people-group-solid.svg';
import personWalkingLuggage from '../../assets/icons/person-walking-luggage-solid.svg';
import sackDollar from '../../assets/icons/sack-dollar-solid.svg';
import starMobileMenu from '../../assets/icons/star-solid.svg';
import user from '../../assets/icons/user-solid.svg';

import chalkboard from '../../assets/icons/chalkboard-user-solid.svg';
import check from '../../assets/icons/check-to-slot-solid.svg';
import graduation from '../../assets/icons/graduation-cap-solid.svg';
import trophy from '../../assets/icons/trophy-solid.svg';
import education from '../../assets/icons/education-program.svg';
import educator from '../../assets/icons/educator.svg';
import Sponsored from '../../assets/icons/Sponsored.svg';
import submit from '../../assets/icons/submit-project.svg';

import "./navbar.scss";
import { CaizCoinPopUp } from "./CaizCoinPopUp";

const closeMenu = (e) => {
  e.preventDefault();
  //document.querySelector("#change-className").classNameList.add('new-className-name');
  document.getElementById("navbarSubMenu").style.display = "none";
  e.stopPropagation();
}


const Navbar = () => {
  const [openMenue, setOpenMenue] = useState(false);
  const [openSubMenue, setOpenSubMenue] = useState(false);
  const [width, setWidth] = useState(window.innerWidth)
  const [firstLink, setFirstLink] = useState(false);
  const [secondLink, setsecondLink] = useState(false);
  const [thirdLink, setThirdLink] = useState(false);
  const [caizCoinPopUp, setCaizCoinPopUp] = useState(false);
  let responsivNavItens;
  const setOpenMenue2 = (e) => {
    setOpenMenue(!openMenue);
    e.preventDefault();
    e.stopPropagation();
  }
  const subMenuOperations = (e) => {
    e.preventDefault();
    setOpenSubMenue(!openSubMenue);
    console.log('subMenuOperations clicked');
    e.stopPropagation();
  }
  if (width <= 521) {
    if (firstLink || secondLink || thirdLink) {
      responsivNavItens = "closeItems";
    } else {
      responsivNavItens = "openItems ";
    }
  }
  function updateState() {
    const windowWidth = window.innerWidth;
    setWidth(windowWidth);
  }
  useEffect(() => {
    updateState();
  }, []);
  window.addEventListener("resize", updateState);
  const handleClick = (e) => {
    e.preventDefault(); 
    window.location.href = "https://caiz.com/join-whitelist/";
  };
  return (
    <>
      <header className="header-navbar">
        <div className="container-fluid">
          <div className="head">
            <div className="row row-flex d-flex flex-wrap align-items-center justify-content-center justify-content-md-between">
              <div className="col-xl-3 col-8">
                <div id="logo" className="mb-3">
                  <a href="https://caizcoin.com/" aria-label="CAIZCOIN" className="d-flex align-items-center col-md-10 mb-2 mb-md-0 text-dark text-decoration-none">
                    <img src={Logo_caiz_new} alt="CAIZCOIN"  width="275" height="53" />
                  </a>
                </div>
              </div>
              <div className="col-xl-9 col-4">
                <div className={openMenue ? "navbarBtn  open" : "navbarBtn"}  onClick={setOpenMenue2}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div id="navbarMobileMenu" className="menu">
                  <ul>
                    <li><a href="https://caiz.com/ecosystem/" title="Ecosystem"><span className="InterR cool-mainlink">Ecosystem</span></a></li>

                    <li id="navbarMobileSubMenu" className={openSubMenue ? "opens  open" : "opens"}><a href="#!" onClick={subMenuOperations}><span className="InterR cool-mainlink" title="Learn Caiz">Learn Caiz</span></a>
                      <div className="submenu-main">
                        <div className="row">
                          <div className="col-xl-4 col-md-6 sub-col-rb">
                            <ul>
                              <li onClick={() => setCaizCoinPopUp(true)}><a href="#"><span className="foInterR cool-link-menu" title="Caiz Coin"><img src={coins} className="icon-style" alt="coins" width="40" height="40" />Caizcoin</span></a></li>
                              <li><a href="" onClick={(e) => {e.preventDefault(); window.location.reload();}}><span className="foInterR cool-link-menu" title="Caiz Earn"><img src={coinshand} className="icon-style" alt="coinshand" width="40" height="40" />Caiz Earn</span></a></li>
                              <li><a href="https://caiz.com/tokenomics/"><span className="foInterR cool-link-menu" title="Tokenomics"><img src={piechart} className="icon-style" alt="piechart" width="40" height="40" />Tokenomics</span></a></li>
                              <li><a href="https://caiz.com/circulating-supply/"><span className="foInterR cool-link-menu" title="Circulating supply<"><img src={circulatingsupply} className="icon-style" alt="circulatingsupply" width="40" height="40" />Circulating supply</span></a></li>
                            </ul>
                          </div>
                          <div className="col-xl-4 col-md-6 sub-col-rb">
                            <ul>
                              <li><a href="https://caiz.com/sharia-certificates/"><span className="foInterR cool-link-menu" title="Sharia Certificates"><img src={sharia} className="icon-style" alt="sharia" width="40" height="40" />Sharia Certificates</span></a></li>
                              <li><a href="https://caiz.com/roadmap/"><span className="foInterR cool-link-menu" title="Roadmap"><img src={roadmap} className="icon-style" alt="roadmap" width="40" height="40" />Roadmap</span></a></li>
                              <li><a href="https://caiz.com/caiz-team/"><span className="foInterR cool-link-menu" title="Caiz Team"><img src={team} className="icon-style" alt="team" width="40" height="40" />Caiz Team</span></a></li>
                              <li><a href="https://caiz.com/ambassadors/"><span className="foInterR cool-link-menu" title="Ambassadors"><img src={ambassadors} className="icon-style" alt="ambassadors" width="40" height="40" />Ambassadors</span></a></li>
                            </ul>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <ul>
                              <li><a href="https://caiz.com/events2024/"><span className="foInterR cool-link-menu" title="Events 2024"><img src={events} className="icon-style" alt="events" width="40" height="40" />Events2024</span></a></li>
                              <li><a href="https://caiz.com/partnerships/"><span className="foInterR cool-link-menu" title="Partnerships"><img src={partnership} className="icon-style" alt="partnership" width="40" height="40" />Partnerships</span></a></li>
                              <li><a href="https://caiz.com/caiz-sponsorships/"><span className="foInterR cool-link-menu" title="Caiz Sponserships"><img src={star} className="icon-style" alt="star" width="40" height="40" />Caiz Sponserships</span></a></li>
                              <li><a href="https://caiz.com/caiz-relief/" onClick={(e) => { e.preventDefault(); window.location.href = 'https://caiz.com/caiz-relief/';}}><span class="foInterR cool-link-menu" title="Caiz Relief"><img src={caizrelief} className="icon-style" alt="caizrelief" width="40" height="40" />Caiz Relief</span></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li id="navbarMobileSubMenu" className={openSubMenue ? "opens  open" : "opens"}><a href="#!" onClick={subMenuOperations}><span className="InterR cool-mainlink" title="Cooperations">Cooperations</span></a>
                      <div className="submenu">
                        <div className="row">
                          <div className="col-xl-4 col-md-6 sub-col-rb">
                            <ul>
                              <li><a href="https://caiz.com/developer-cooperations-program/"><span className="foInterR cool-link" title="Developer Cooperation"><img src={developers} className="icon-style" alt="developers" width="40" height="40" />Developer Cooperation</span></a></li>
                              <li><a href="https://caiz.com/marketing-cooperations-program/"><span className="foInterR cool-link" title="Marketing Cooperations"><img src={marketing} className="icon-style" alt="marketing" width="40" height="40" />Marketing Cooperations</span></a></li>
                              <li><a href="https://caiz.com/become-an-advocate/"><span className="foInterR cool-link" title="Become an Advocate<"><img src={suitcase} className="icon-style" alt="suitcase" width="40" height="40" />Become an Advocate</span></a></li>
                              <li><a href="https://caiz.com/freelance-with-caiz/"><span className="foInterR cool-link" title="Freelance with Caiz"><img src={desktop} className="icon-style" alt="desktop" width="40" height="40" />Freelance with Caiz</span></a></li>
                              <li><a href="https://caiz.com/influencers/"><span className="foInterR cool-link" title="Influencers"><img src={influencers} className="icon-style" alt="influencers" width="40" height="40" />Influencers</span></a></li>
                              <li><a href="https://caiz.com/caizcoin-bounty-program//"><span className="foInterR cool-link" title="Bounty Program"><img src={gift} className="icon-style" alt="gift" width="40" height="40" />Bounty Program</span></a></li>
                              <li><a href="https://caiz.com/become-an-educator/"><span className="foInterR cool-link" title="Become an Educator"><img src={educator} className="icon-style" alt="gift" width="40" height="40" />Become an Educator</span></a></li>
                              <li><a href="https://caiz.com/caiz-education-program/"><span className="foInterR cool-link" title="Education Program"><img src={education} className="icon-style" alt="gift" width="40" height="40" />Education Program</span></a></li>                              
                              <li><a href="https://caiz.com/sponsored-by-caiz/"><span className="foInterR cool-link" title="Sponsored by Caiz"><img src={Sponsored} className="icon-style" alt="gift" width="40" height="40" />Sponsored by Caiz</span></a></li>
                              <li><a href="https://caiz.com/submit-your-project/"><span className="foInterR cool-link" title="Submit your project"><img src={submit} className="icon-style" alt="gift" width="40" height="40" />Submit your project</span></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li id="navbarMobileSubMenu" className={openSubMenue ? "opens  open" : "opens"}><a href="#!" onClick={subMenuOperations}><span className="InterR cool-mainlink" title="Communication">Communication</span></a>
                      <div className="submenu">
                        <div className="row">
                          <div className="col-xl-4 col-md-6 sub-col-rb">
                            <ul>
                              <li><a href="https://caiz.com/community/"><span className="foInterR cool-link" title="Community"><img src={community} className="icon-style" alt="community" width="40" height="40" />Community</span></a></li>
                              <li><a href="https://caiz.com/institutional-contacts/"><span className="foInterR cool-link" title="Institutional Contacts"> <img src={contacts} className="icon-style" alt="contacts" width="40" height="40"/>Institutional Contacts </span></a></li>
                              <li><a href="https://caiz.com/press-and-media-inquiries/"><span className="foInterR cool-link" title="Press and Media Inquires"><img src={press} className="icon-style" alt="press" width="40" height="40"/>Press and Media Inquires</span></a></li>
                              <li><a href="https://caiz.com/downloads/"><span className="foInterR cool-link" title="Downloads"><img src={downloads} className="icon-style" alt="downloads" width="40" height="40"/>Downloads</span></a></li>
                              <li><a href="https://caiz.com/wordings/"><span className="foInterR cool-link" title="Wordings"><img src={wordings} className="icon-style" alt="wordings" width="40" height="40"/>Wordings</span></a></li>
                              <li><a href="https://caiz.com/feedback/"><span className="foInterR cool-link" title="Feedback"><img src={like} className="icon-style" alt="like" width="40" height="40"/>Feedback</span></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li><button onClick={handleClick} type="button" className="join-caiz-button"> Join Caiz Whitelist</button></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className={openMenue ? "animatedNavbar" : "animatedNavbarClose"}>
        <svg  onClick={() => { setOpenMenue(false); }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="svgCross"><path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" /></svg>
        <div className="smallNavHamburger" onClick={setOpenMenue2}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div id="smallScreen" className={"animatedNavbarItems"}>
          <div className={responsivNavItens}>
            <div className={firstLink || secondLink || thirdLink ? "navbarListItemClose" : "navbarListItemOpen"}>
              <a href="https://caiz.com/ecosystem/"><span className="foInter" title="Ecosystem"><img src={globe} className="icon-style" alt="globe" width="25" height="25" />Ecosystem </span></a>
              <a href="#" onClick={() => {setFirstLink(true); setsecondLink(false); setThirdLink(false);}}><span className="foInter"  title="Learn Caiz"><img src={list} className="icon-style" alt="list" width="25" height="25" />Learn Caiz <img src={iconForward} className="icon-arrow" alt="iconForward" width="25" height="25" /></span></a>
              <a href="#" onClick={() => {setFirstLink(false); setsecondLink(true); setThirdLink(false);}}> <span className="foInter"  title="Cooperations"><img src={handshakeAngle} className="icon-style" alt="handshakeAngle" width="25" height="25" />Cooperations <img src={iconForward} className="icon-arrow" alt="iconForward" width="25" height="25" /></span></a>
              <a href="#" onClick={() => {setFirstLink(false); setsecondLink(false); setThirdLink(true);}}><span className="foInter" title="Communication"><img src={comments} className="icon-style" alt="comments" width="25" height="25" />Communication <img src={iconForward} className="icon-arrow" alt="iconForward" width="25" height="25" /></span></a>
              <a href="https://caizcareers.com/" onClick={(e) => { e.preventDefault(); window.location.href = 'https://caizcareers.com/';}}><span className="foInter" title="Hiring"><img src={users} className="icon-style" alt="users" width="25" height="25" />We're hiring</span></a>
            </div>

            <button onClick={handleClick} type="button" className="join-caiz-mobile-button"> Join Caiz Whitelist</button>

            <h5 className="foInter socialMediaText">Find us on social media</h5>

            <div class="social-icons-container">
                <a class="social-icon"  rel="noopener" href="https://www.tiktok.com/@caizcoin" target="_blank"><img src={tiktok} className="lazy" alt="tiktok" width="25" height="25" /></a>
                <a class="social-icon"  rel="noopener" href="https://www.linkedin.com/company/caizcoin/" target="_blank"><img src={linkedln} className="lazy" alt="linkedin" width="25" height="25" /></a>
                <a class="social-icon"  rel="noopener" href="https://twitter.com/caizcoin" target="_blank"><img src={twitter} className="lazy" alt="twitter" width="25" height="25" /></a>
                <a class="social-icon"  rel="noopener" href="https://www.reddit.com/r/Caizcoin/" target="_blank"><img src={reddit} className="lazy" alt="reddit" width="25" height="25" /></a>
                <a class="social-icon"  rel="noopener" href="https://www.instagram.com/caizcoin/" target="_blank"><img src={instagram} className="lazy" alt="instagram" width="25" height="25" /></a>
                {/* <a class="social-icon" rel="noopener" href="https://discord.gg/J8c8A5zst2" target="_blank"><img src={discord} className="lazy" alt="discord" width="25" height="25" /></a> */}
                <a class="social-icon" rel="noopener" href="https://www.facebook.com/Caizcoin/" target="_blank"><img src={facebook} className="lazy" alt="facebook" width="25" height="25"/></a>
                <a class="social-icon" rel="noopener" href="https://t.me/caizcoin_official" target="_blank"><img src={telegram} className="lazy" alt="pinterest" width="25" height="25" /></a>
                <a class="social-icon" rel="noopener" href="https://www.youtube.com/channel/UCiUX3Q8oga8cQRp7foeuR4A" target="_blank"><img src={youtube} className="lazy" alt="youtube" width="25" height="25" /></a> 
                <a class="social-icon" rel="noopener" href="https://whatsapp.com/channel/0029VaEwznQKrWQyx20aNR03" target="_blank"><img src={whatsapp} className="lazy" alt="whatsapp" width="25" height="25" /></a>
                <a class="social-icon" rel="noopener" href="https://caizcoin.medium.com/" target="_blank"><img src={medium} className="lazy" alt="medium" width="25" height="25" /></a>
            </div>

          </div>
        </div>
        <div className={firstLink ? "animatesideLinkArea1" : "animatesideLinkAreaClose1"}>
          <div className="animatesideLinkAreaHeader" onClick={() => setFirstLink(false)}><img src={iconBackward} alt="iconBackward" width="25" height="25" /><div>Learn Caiz</div></div>
          <a href="#"><span onClick={() => setCaizCoinPopUp(true)} className="foInter animatesideAreaLink" title="Caiz Coin "><img src={coinsMobileMenu} className="icon-menu-style" alt="coins" width="25" height="25" />Caizcoin</span></a>
          <a href="" onClick={(e) => { e.preventDefault();   window.location.reload(); }}><span className="foInter animatesideAreaLink" title="Caiz Earn"><img src={handHoldingDollar} className="icon-menu-style" alt="handHoldingDollar" width="25" height="25" />Caiz Earn</span></a>
          <a href="https://caiz.com/tokenomics/"><span className="foInter animatesideAreaLink" title="Tokenomics"><img src={chartPie} className="icon-menu-style" alt="chartPie" width="25" height="25" />Tokenomics</span></a>
          <a href="https://caiz.com/circulating-supply/"> <span className="foInter animatesideAreaLink" title="Circulating supply<"><img src={chartSimple} className="icon-menu-style" alt="chartSimple" width="25" height="25" />Circulating supply</span></a>                        
          <a href="https://caiz.com/sharia-certificates/"><span className="foInter animatesideAreaLink" title="Sharia Certificates"><img src={fileCircleCheck} className="icon-menu-style" alt="fileCircleCheck" width="25" height="25" />Sharia Certificates</span></a>
          <a href="https://caiz.com/roadmap/"><span className="foInter animatesideAreaLink" title="Roadmap"><img src={map} className="icon-menu-style" alt="map" width="25" height="25" />Roadmap</span></a>
          <a href="https://caiz.com/caiz-team/"><span className="foInter animatesideAreaLink" title="Caiz Team"><img src={user} className="icon-menu-style" alt="user" width="25" height="25" />Caiz Team</span></a>
          <a href="https://caiz.com/ambassadors/"><span className="foInter animatesideAreaLink" title="Ambassadors"><img src={users} className="icon-menu-style" alt="users" width="25" height="25" />Ambassadors</span></a>
          <a href="https://caiz.com/events2024/"><span className="foInter animatesideAreaLink" title="Events 2024"><img src={calendarDays} className="icon-menu-style" alt="calendarDays" width="25" height="25" />Events2024</span></a>
          <a href="https://caiz.com/partnerships/"><span className="foInter animatesideAreaLink" title="Partnerships"><img src={handshakeAngle} className="icon-menu-style" alt="handshakeAngle" width="25" height="25" />Partnerships</span></a>
          <a href="https://caiz.com/caiz-sponsorships/"><span className="foInter animatesideAreaLink" title="Caiz Sponserships"><img src={starMobileMenu} className="icon-menu-style" alt="star" width="25" height="25" />Caiz Sponserships</span></a>
          <a href="https://caiz.com/caiz-relief/" onClick={(e) => { e.preventDefault(); window.location.href = 'https://caiz.com/caiz-relief/';}}><span class="foInter animatesideAreaLink" title="Caiz Relief"><img src={heart} className="icon-menu-style" alt="heart" width="25" height="25" />Caiz Relief</span></a>
        </div>
        <div className={secondLink ? "animatesideLinkArea2" : "animatesideLinkAreaClose2"}>
          <div className="animatesideLinkAreaHeader" onClick={() => setsecondLink(false)}><img src={iconBackward} alt="iconBackward" width="25" height="25" /><div>Cooperations</div></div>
          <a href="https://caiz.com/developer-cooperations-program/"><span className="foInter animatesideAreaLink" title="Developer Cooperation"><img src={laptopCode} className="icon-menu-style" alt="laptopCode" width="25" height="25" />Developer Cooperation</span></a>
          <a href="https://caiz.com/marketing-cooperations-program/"><span className="foInter animatesideAreaLink" title="Marketing Cooperations"><img src={bullhorn} className="icon-menu-style" alt="bullhorn" width="25" height="25" />Marketing Cooperations</span></a>
          <a href="https://caiz.com/become-an-advocate/"><span className="foInter animatesideAreaLink" title="Become an Advocate<"><img src={personWalkingLuggage} className="icon-menu-style" alt="personWalkingLuggage" width="25" height="25" />Become an Advocate</span></a>
          <a href="https://caiz.com/freelance-with-caiz/"> <span className="foInter animatesideAreaLink" title="Freelance with Caiz"><img src={peopleGroup} className="icon-menu-style" alt="peopleGroup" width="25" height="25" />Freelance with Caiz</span></a>
          <a href="https://caiz.com/influencers/"> <span className="foInter animatesideAreaLink" title="Influencers"><img src={hashtag} className="icon-menu-style" alt="hashtag" width="25" height="25" />Influencers</span></a>
          <a href="https://caiz.com/caizcoin-bounty-program//"> <span className="foInter animatesideAreaLink" title="Bounty Program"><img src={sackDollar} className="icon-menu-style" alt="sackDollar" width="25" height="25" />Bounty Program</span></a>
          <a href="https://caiz.com/become-an-educator/"> <span className="foInter animatesideAreaLink" title="Become an Educator"><img src={chalkboard} className="icon-menu-style" alt="sackDollar" width="25" height="25" />Become an Educator</span></a>
          <a href="https://caiz.com/caiz-education-program/"> <span className="foInter animatesideAreaLink" title="Education Program"><img src={graduation} className="icon-menu-style" alt="sackDollar" width="25" height="25" />Education Program</span></a>
          <a href="https://caiz.com/sponsored-by-caiz/"> <span className="foInter animatesideAreaLink" title="Sponsored by Caiz"><img src={trophy} className="icon-menu-style" alt="sackDollar" width="25" height="25" />Sponsored by Caiz</span></a>
          <a href="https://caiz.com/submit-your-project/"> <span className="foInter animatesideAreaLink" title="Submit your project"><img src={check} className="icon-menu-style" alt="sackDollar" width="25" height="25" />Submit your project</span></a>
        </div>
        <div className={thirdLink ? "animatesideLinkArea3" : "animatesideLinkAreaClose3"}>
          <div className="animatesideLinkAreaHeader" onClick={() => setThirdLink(false)}><img src={iconBackward} alt="iconBackward" width="25" height="25" /><div>Communication</div></div>
          <a href="https://caiz.com/community/"><span className="foInter animatesideAreaLink" title="Community"><img src={peopleGroup} className="icon-menu-style" alt="peopleGroup" width="25" height="25" />Community</span></a>
          <a href="https://caiz.com/institutional-contacts/"><span className="foInter animatesideAreaLink" title="Institutional Contacts"><img src={building} className="icon-menu-style" alt="building" width="25" height="25" />Institutional Contacts</span></a>
          <a href="https://caiz.com/press-and-media-inquiries/"><span className="foInter animatesideAreaLink" title="Press and Media Inquires"><img src={newspaper} className="icon-menu-style" alt="newspaper" width="25" height="25" />Press and Media</span></a>
          <a href="https://caiz.com/downloads/"> <span className="foInter animatesideAreaLink" title="Downloads"><img src={download} className="icon-menu-style" alt="download" width="25" height="25" />Downloads</span></a>
          <a href="https://caiz.com/feedback/"> <span className="foInter animatesideAreaLink" title="Feedback"><img src={comments} className="icon-menu-style" alt="comments" width="25" height="25" />Feedback</span></a>
        </div>
      </div>
      <CaizCoinPopUp
        caizCoinPopUp={caizCoinPopUp}
        setCaizCoinPopUp={setCaizCoinPopUp}
      />
    </>
  );
};

export default Navbar;
