import React from "react";

const Downloads = () => {
  return (
    <>
      <xml version="1.0" encoding="utf-8" />
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 200 200"
        color="red"
        width={25}
        // style="enable-background:new 0 0 200 200;"
        xmlSpace="preserve"
      >
        <g>
          <g>
            <path
              d="M45,52h32c1.1,0,2,0.4,2.8,1.2C80.6,54,81,54.9,81,56s-0.4,2-1.2,2.8C79,59.6,78.1,60,77,60H53v88h88V60h-24
                c-1.1,0-2-0.4-2.8-1.2S113,57.1,113,56s0.4-2,1.2-2.8c0.8-0.8,1.7-1.2,2.8-1.2h32v104H45V52z M97,124l25-29.4
                c0.7-0.8,1-1.8,0.9-2.9s-0.5-2-1.3-2.7s-1.8-1-2.9-0.9s-2,0.5-2.7,1.2L101.2,107V32c0-1.1-0.4-2-1.2-2.8c-0.8-0.8-1.7-1.2-2.8-1.2
                c-1.2,0-2.1,0.4-2.9,1.2c-0.8,0.8-1.1,1.7-1.1,2.8v75L78.4,89.6c-0.4-0.5-0.9-0.9-1.4-1.1s-1.1-0.3-1.6-0.3s-1,0.1-1.4,0.2
                c-0.4,0.2-0.8,0.4-1.2,0.8c-1,0.6-1.5,1.4-1.6,2.5s0.2,2,0.9,2.9L97,124z"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default Downloads;
