import React from "react";

const Whitepaper = () => {
  return (
    <>
      <xml version="1.0" encoding="utf-8" />
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 200 200"
        xmlSpace="preserve"
        width={25}
      >
        <g>
          <path d="M161,34.6v128H71.4L33,124.2V34.6H161z M153,42.6H41v72h40v40h72V42.6z M42.6,122.6L73,152.9v-30.4H42.6z" />
        </g>
      </svg>
    </>
  );
};

export default Whitepaper;
