import "./CaizCoinPopUp.scss";
import closeIcon from '../../assets/icons/close-icon.svg';
import databaseCheck from '../../assets/Popup/database-check.svg';
import databaseEdit from '../../assets/Popup/database-edit.svg';
import databaseSecure from '../../assets/Popup/database-secure.svg';
import globe from '../../assets/Popup/globe.svg';

export const CaizCoinPopUp = ({ caizCoinPopUp, setCaizCoinPopUp }) => {

return (
<>
  <script src="https://cdn.lordicon.com/lordicon.js"></script>
  <div
    className={caizCoinPopUp ? "popUpContainer" : "popUpHidden"}
    onClick={() => setCaizCoinPopUp(false)}
  >
    <div
      className="item1"
      onClick={() => {
        if (caizCoinPopUp) {
          setCaizCoinPopUp(true);
        }
      }}
    >
      <img
        src={closeIcon}
        className="crosIcon"
        alt="closeIcon"
        width="40"
        height="40"
        onClick={() => setCaizCoinPopUp(false)}
      />
       {/* Include Lordicon here */}
       <lord-icon
        src="https://cdn.lordicon.com/wyqtxzeh.json"
        trigger="hover"
        colors="primary:#ffffff,secondary:#ffffff"        
        style={{ width: "150px", height: "150px" }}>
        </lord-icon>
      <h4 className="heading">CAIZcoin</h4>
      <p className="description">
        The CAIZcoin Token is a digital currency (a payment token) that
        serves as a payment mechanism within the CAIZchain, enabling fast
        and secure transactions while also functioning as a store of value.
        Its primary function lies in providing access to the applications,
        products, and services available within the ecosystem, including
        buying and selling goods and services, participating in CAIZearn and
        accessing premium features.
      </p>
    </div>{" "}
    <div
      className="item2"
      onClick={() => {
        if (caizCoinPopUp) {
          setCaizCoinPopUp(true);
        }
      }}
    >
      <div>
        <img src={databaseEdit} className="" alt="databaseEdit" width="40" height="40" />
        Tokenname: CAIZcoin
      </div>
      <div>
        <img src={databaseCheck} className="" alt="databaseCheck" width="40" height="40" />
        Ticker: CAIZ
      </div>
      <div>
        <img src={globe} className="" alt="globe" width="40" height="40" />
        Network: ERC-20
      </div>
      <div>
        <img src={databaseSecure} className="" alt="databaseSecure" width="40" height="40" />
        Token: Payment-Token
      </div>
    </div>
  </div>
</>
);
};