import React from "react";

const Lightpaper = () => {
  return (
    <>
      <xml version="1.0" encoding="utf-8" />
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 200 200"
        width={25}
        xmlSpace="preserve"
      >
        <g>
          <path
            d="M55.6,159.5v7.9H25v-48.1h30.6v4c0.7-0.3,1.5-0.5,2.3-0.7c0.8-0.2,1.6-0.4,2.5-0.7c2.1-0.5,4.5-1.1,7.2-1.7
            c2.7-0.6,6-0.9,9.9-0.9c0.7,0,1.5,0,2.2,0c0.7,0,1.5,0,2.2,0V27.5h49.9L165,60.7v71.5h-47.3c1,1.4,1.8,2.8,2.3,4.3s0.9,3.1,1,4.7
            c6.9,0.2,13.1,0.7,18.4,1.4c5.3,0.8,9.8,1.8,13.3,2.9c5.1,1.7,8.4,3.7,10,6c1.5,2.3,2.3,4.4,2.3,6.3c0,2.6-1,4.8-2.9,6.7
            s-3.9,2.9-5.9,2.9H77.5c-2.7,0-5.3-0.5-7.8-1.5c-2.5-1-4.9-2.1-7.2-3.3c-1.3-0.5-2.5-1.1-3.6-1.7C57.8,160.4,56.7,159.9,55.6,159.5
            z M46.9,128.1H33.7v30.6h13.1V128.1z M62.9,130.6c-1.2,0.3-2.4,0.6-3.6,0.9c-1.2,0.3-2.4,0.5-3.7,0.6v18.3c1.8,0.5,3.6,1.1,5.4,1.9
            s3.5,1.6,5.3,2.5c2,1,4,1.9,5.9,2.7s3.7,1.2,5.3,1.2h78.1c0.3-0.1,0.5-0.2,0.5-0.4c0.1-0.2,0.1-0.3,0.1-0.4c0-0.2-2.2-1.5-6.6-4.1
            c-4.4-2.6-16.8-3.8-37.2-3.8c-10.8,0-18.9-0.8-24.1-2.3c-5.2-1.5-8-2.4-8.3-2.5c-1.1-0.5-1.9-1.3-2.3-2.4c-0.5-1.1-0.4-2.3,0.1-3.3
            c0.5-1.1,1.3-1.9,2.4-2.3c1.1-0.5,2.3-0.4,3.3,0.1c0.1,0,2.5,0.7,7.2,2c4.7,1.3,11.8,2,21.4,2c-0.5-2.9-2.2-5.2-5.1-7
            c-2.7-1.8-5.9-3.2-9.5-4c-3.6-0.9-7.2-1.4-10.9-1.7c-3.7-0.3-6.8-0.4-9.3-0.4c-3.3,0-6.1,0.2-8.3,0.7
            C66.9,129.2,64.8,129.8,62.9,130.6z M90.6,120c2.9,0.5,5.6,1,8.2,1.5c2.6,0.5,4.9,1.3,7.1,2.2h50.3V66.9h-30.6V36.2h-35V120z
             M134.4,58.1h15.7l-15.7-15.7V58.1z"
          />
        </g>
      </svg>
    </>
  );
};

export default Lightpaper;
