import React from "react";

const Greenpaper = () => {
  return (
    <>
      <xml version="1.0" encoding="utf-8" />
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 200 200"
        // style="enable-background:new 0 0 200 200;"
        xmlSpace="preserve"
        width={25}
      >
        <g>
          <path
            d="M54,31.9h64.2L154,67.7v97.5H54V31.9z M62.3,156.9h83.3V73.5h-33.3V40.2h-50V156.9z M123.4,87.3c0.8-0.8,1.7-1.2,2.9-1.2
            c1.1,0,2.1,0.4,3,1.2c0.8,0.8,1.2,1.7,1.2,2.9c0,1.1-0.4,2.1-1.2,2.9l-32.2,32.2l-17.4-17.6c-0.9-0.8-1.3-1.7-1.3-2.9
            c0-1.1,0.4-2.1,1.3-2.9c0.8-0.9,1.7-1.3,2.9-1.3c1.1,0,2.1,0.4,3,1.3l11.6,11.6L123.4,87.3z M120.7,65.2h19.1l-19.1-19.1V65.2z"
          />
        </g>
      </svg>
    </>
  );
};

export default Greenpaper;
